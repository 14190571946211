import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import "./Nav.scss";
import LogoSVG from "../../images/z4m-logo-v3.svg";

export default () => {
  const [navBorder, setNavBorder] = useState(false);
  const [hamburgerOpen, setHamburgerOpen] = useState(false);

  useEffect(() => {
    document.addEventListener("scroll", function(e) {
      if (window.scrollY > 20) {
        setNavBorder(true);
      }
      if (window.scrollY < 20) {
        setNavBorder(false);
      }
    });
  });

  const handleHamburger = () => {
    setHamburgerOpen(!hamburgerOpen);
  };

  return (
    <nav
      className={`navbar ${navBorder && !hamburgerOpen ? "scrolled" : ""} `}
      id="nav"
    >
      <Link className="navbar-brand" href="/">
        <img src={LogoSVG} width="80" height="40" alt="Z4M Logo" />
      </Link>

      <div className="navbar-nav">
        <div className="nav-item">
          <div className="nav-link">
            <Link to="/about" activeClassName="active">
              About
            </Link>
          </div>
        </div>
        <div className="nav-item">
          <div className="nav-link">
            <Link to="/one-on-one" activeClassName="active">
              One-on-One
            </Link>
          </div>
        </div>
        <div className="nav-item">
          <div className="nav-link">
            <a href={`https://zest4maths.thinkific.com`} target={`_blank`}>
              Online Courses
            </a>
          </div>
        </div>
        <div className="nav-item">
          <div className="nav-link">
            <Link to="/faq" activeClassName="active">
              FAQs
            </Link>
          </div>
        </div>
        {/* <div className="nav-item">
          <div className="nav-link">
            <Link to="/blog" activeClassName="active">
              Blog
            </Link>
          </div>
        </div> */}
      </div>
      <button
        className={`hamburger hamburger--collapse ${
          hamburgerOpen ? "is-active" : ""
        }`}
        type="button"
        onClick={handleHamburger}
      >
        <span className="hamburger-box">
          <span className="hamburger-inner"></span>
        </span>
      </button>

      <div className={`navbar-nav-mobile ${hamburgerOpen ? "open" : ""}`}>
        <div className="nav-item">
          <div className="nav-link">
            <Link to="/about" activeClassName="active">
              About
            </Link>
          </div>
        </div>
        <div className="nav-item">
          <div className="nav-link">
            <Link to="/one-on-one" activeClassName={"active"}>
              One-on-One
            </Link>
          </div>
        </div>
        <div className="nav-item">
          <div className="nav-link">
            <a href={`https://zest4maths.thinkific.com`} target={`_blank`}>
              Online Courses
            </a>
          </div>
        </div>
        <div className="nav-item">
          <div className="nav-link">
            <Link to="/faq" activeClassName="active">
              FAQs
            </Link>
          </div>
        </div>
        {/* <div className="nav-item">
                  <div className="nav-link"><Link to="/blog" activeClassName="active">Blog</Link></div>
                </div> */}
      </div>
    </nav>
  );
};
