import React from 'react';
import './Button.scss'


export default (props) => {

    
    return (
        <button onClick={props.handleClick} className={`btn ` + props.type}>{props.label}</button>
    )
}