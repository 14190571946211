import React from 'react'
import Nav from '../components/Nav/Nav'
import Footer from '../components/Footer/Footer'

export default ({ children }) => (
    <div>
        <Nav />
        { children }
        {/* <Footer /> */}
    </div>
)

