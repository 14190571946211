import React from 'react'
import Layout from '../components/Layout'
import PageTitle from '../components/PageTitle/PageTitle'
import ContentBlock from '../components/LandingContentBlock/ContentBlock'

import '../pages/scss/404.scss';
import Button from '../components/Button/Button';

const redirectBack = () => {
    document.location.href="/";
}

export default () => (

    

    <Layout>
        <ContentBlock class={'full-screen'}>
            <div className='four-oh-four-container'>
            <div className='four-oh-four-container__title'>🌻</div>
                <h1 className='four-oh-four-container__title'>404</h1>
                <div className="four-oh-four-container__text">
                    Whoops! Looks like you got lost, hit the button below to return to zest4maths.co.nz
                </div>
                <div className="four-oh-four-container__btn">
                <Button type={'btn-yellow'} label="Take Me Back" handleClick={redirectBack}></Button>
                </div>
                
            </div>
            
        </ContentBlock>

    </Layout>
)