import React from 'react'

import './ContentBlock.scss'

export default (props) => {

    return(
        <div className={`block-container ` + props.class} id={props.id}>
            { props.children }
        </div>
    )
}